<template>
  <div>
    <b-modal
      id="zalo-list-modal"
      ref="zalo-list-modal"
      title="Danh sách nhóm Zalo"
      size="xl"
      :hide-header-close="false"
      :no-close-on-backdrop="true"
      @cancel="resetModal"
      @hide="resetModal"
      hide-footer
    >
      <b-overlay>
        <b-container class="p-0">
          <form ref="form">
            <b-row>
              <b-col>
                <template-table
                  :column="column"
                  :data="this.zaloGroup"
                  :paging="paging"
                  :tableAction="false"
                  :selectAction="false"
                  :searchAction="false"
                  boundary="window"
                >
                  <template v-slot:body="{ item }">
                    <td>
                      <div class="grap-info">
                        <avatar
                          size="40px"
                          :src="item.avatar"
                          :rounded="true"
                        />

                        <div class="d-flex flex-column ml-3">
                          <p class="mb-0 module-list-page__body__updater-name">
                            {{ item.name }}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td style="width: 15px">
                      <action-dropdown
                        :value="item"
                        :show_edit="true"
                        :show_delete="isWriteAllPermission"
                        :show_copy="false"
                        :boundary="data.length > 2 ? 'scrollParent' : 'window'"
                        :show_view="true"
                        @edit="editItem(item)"
                        @view="viewItem(item.group_id)"
                        @delete="showPopupDeleteStatus(item.group_id)"
                      >
                      </action-dropdown>
                    </td>
                    <td style="width: 30px">
                      {{ item.total_member - 1 }}
                    </td>
                    <td >
                      {{ item.group_description }}
                    </td>
                    <td>
                      {{ item.group_link }}
                    </td>
                  </template>
                </template-table>
                <div class="d-flex justify-content-end align-items-center mt-3">
                  <b-button
                    class="btn btn-plain ml-2"
                    href="#"
                    @click.stop="handleCancel"
                    tabindex="0"
                  >
                    Thoát
                  </b-button>
                  <b-button
                    class="btn btn-success ml-2"
                    href="#"
                    @click.stop="handleRefresh"
                    tabindex="0"
                    :disabled="isSubmitting"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                      />
                    </span>
                    Làm mới
                  </b-button>
                  <b-button
                    class="btn btn-success ml-2"
                    type="button"
                    @click="showModalCreate"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/plus.svg"
                      />
                    </span>
                    Thêm mới
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </form>
        </b-container>
      </b-overlay>

      <template #modal-footer>
        <!-- Your modal footer buttons go here -->
      </template>
    </b-modal>

    <b-modal
      id="detail-modal"
      ref="detail-modal"
      title="Thông tin của nhóm"
      size="xl"
      @ok="handleSuccessOk"
      hide-footer
    >
      <b-container>
        <row>
          <b-overlay>
            <b-container class="p-0">
              <form ref="form">
                <b-row>
                  <b-col>
                    <template-table
                      :column="columnDetail"
                      :data="this.zaloGroupDetail"
                      :paging="paging"
                      :tableAction="false"
                      :selectAction="false"
                      :searchAction="false"
                      boundary="window"
                    >
                      <template v-slot:body="{ item }">
                        <td v-if="!item.oa_id">
                          <div class="grap-info">
                            <avatar
                              size="100px"
                              :src="item.avatar"
                              :rounded="true"
                            />
                            <div class="d-flex flex-column ml-3">
                              <p style="font-size: 16px">
                                {{ item.name }}
                              </p>
                            </div>
                          </div>
                        </td>

                        <td v-if="!item.oa_id">
                          {{ item.user_id }}
                        </td>
                        <td
                          style="margin-top: 40px; display: block"
                          v-if="!item.oa_id"
                        >
                          <basic-select
                            v-if="
                              !listExisted.some(
                                (x) => x.zaloUserId === item.user_id,
                              )
                            "
                            placeholder="--- Chọn số ---"
                            name="phoneListMapping"
                            :options="phoneListMapping"
                            value-label="text"
                            track-by="text"
                            :multiple="false"
                            v-model="selectedPhone"
                          />
                          {{ getPhoneNumber(item.user_id) }}
                        </td>

                        <td v-if="!item.oa_id">
                          <b-button
                            v-if="
                              !listExisted.some(
                                (x) => x.zaloUserId === item.user_id,
                              )
                            "
                            class="btn btn-success ml-3"
                            type="button"
                            @click="
                              submitLink(
                                item.user_id,
                                splitSelectedPhone(item.user_id),
                              )
                            "
                          >
                            Liên kết
                          </b-button>

                          <b-button
                            v-if="
                              listExisted.some(
                                (x) => x.zaloUserId === item.user_id,
                              )
                            "
                            class="btn btn-inactive ml-3"
                            type="button"
                            @click="showPopupUnLink(item.user_id)"
                          >
                            Hủy liên kết
                          </b-button>
                        </td>
                      </template>
                    </template-table>
                  </b-col>
                </b-row>
              </form>
            </b-container>
          </b-overlay>
        </row>
      </b-container>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          class="btn btn-plain ml-2"
          href="#"
          @click.stop="cancelItem"
          tabindex="0"
        >
          Quay lại
        </b-button>
      </div>
    </b-modal>
    <ZaloModal ref="zalo-modal" @call-refresh-list="getGroupOA" />
    <ZaloEditModal
      ref="zalo-edit-modal"
      @call-refresh-list="getGroupOA"
      :groupItem="groupItem"
    />
  </div>
</template>
<script>
import ZaloModal from './ZaloModal.vue';
import ZaloEditModal from './ZaloEditModal.vue';

export default {
  components: {
    ZaloModal,
    ZaloEditModal,
  },
  name: 'ZaloListModal',
  props: {
    coursesList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {},
  computed: {
    // splitSelectedPhone(user_id) {
    //   if (this.selectedPhone) {
    //      this.parts = this.selectedPhone.split('|');
    //     return this.parts[1];
    //   }
    //   else if (this.parts[1]===null)
    //   {
    //     return this.getPhoneNumber(user_id)
    //   }
    //   return null;
    // },
  },
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 5,
        total: 0,
      },
      data: '',
      selectedPackage: '',
      coursesId: '',
      column: [
        {
          key: 'name',
          label: 'Tên nhóm zalo',
          sortable: false,
          style: {
            width: '75%',
          },
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
          style: {
            width: '5%',
          },
        },
        {
          key: 'total_member',
          label: 'Số lượng',
          sortable: false,
          style: {
            width: '100%',
          },
        },
        {
          key: 'group_description',
          label: 'Mô tả nhóm',
          sortable: false,
          style: {
            width: '50%',
          },
        },
        {
          key: 'group_link',
          label: 'Link tham gia nhóm',
          sortable: false,
        },
      ],
      columnDetail: [
        {
          key: 'name',
          label: 'Tên thành viên',
          sortable: false,
          style: {
            width: '45%',
          },
        },

        {
          key: 'user_id',
          label: 'User Zalo Id',
          sortable: false,
          style: {
            width: '15%',
          },
        },

        {
          key: 'phone_group',
          label: 'Tài khoản liên kết',
          sortable: false,
          style: {
            width: '25%',
          },
        },
        {
          key: 'mapping_phone',
          label: 'Liên kết',
          sortable: false,
          style: {
            width: '15%',
          },
        },
      ],

      coach_id: '',
      isSubmitting: false,
      zaloGroup: [],
      zaloGroupDetail: [],
      phoneListMapping: [],
      selectedPhone: null,
      listExisted: [],
      parts: [],
      groupItem: [],
    };
  },

  async created() {
    this.getGroupOA();
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    truncateText(text, maxLength) {
      if (!text) return '';
      return text.length > maxLength
        ? text.substring(0, maxLength) + '...'
        : text;
    },
    cancelItem() {
      this.$refs['detail-modal'].hide();
    },
    async viewItem(group_id) {
      this.$store.commit('context/setLoading', true);

      try {
        const params = {
          group_id: group_id,
          offset: 0,
          count: 50,
        };

        const course = this.coursesList.find((x) => x.zaloGroupId === group_id);
        if (course !== null) {
          this.coursesId = course.id;
          this.getPhoneList(this.coursesId);
        } else {
          this.$toastr.e({
            title: 'Warning',
            msg: 'Nhóm zalo chưa được chỉ định cho khóa',
          });
          return;
        }

        const response = await this.$api.get('/ZaloZnsLog/DetailGroup', {
          params,
        });

        this.zaloGroupDetail = response.data.members;
        this.paging.total = response.data.total;

        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Lấy danh sách nhóm thành công.',
        });
        this.$refs['detail-modal'].show();
      } catch (error) {
        this.$toastr.w({
          title: 'Cảnh báo!',
          msg: 'Nhóm chưa được chỉ định vào khóa.',
        });
        console.error(error);
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    async deletedGroup(group_id) {
      try {
        // Construct the JSON body
        const body = {
          group_id: group_id,
        };

        // Make the API call with JSON body
        await this.$api.post('/ZaloZnsLog/DeleteGroupOa', body, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        // Display success message
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Nhóm zalo được xóa thành công.',
        });
      } catch (error) {
        // Display error message
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
        this.isSubmitting = false;
      }
    },
    async showPopupDeleteStatus(group_id) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn xóa nhóm không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        async function (result) {
          if (result) {
            await this.deletedGroup(group_id);
            await this.getGroupOA();
          }
        }.bind(this),
      );
    },

    async editItem(item) {
      this.groupItem = item;
      this.$refs['zalo-edit-modal'].show();
    },

    async showPopupUnLink(user_id) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn bỏ liên kết tài khoản không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        async function (result) {
          if (result) {
            await this.submitUnLink(user_id, this.splitSelectedPhone(user_id));
          }
        }.bind(this),
      );
    },
    showModalCreate() {
      this.$refs['zalo-modal'].show();
    },

    getPhoneNumber(user_id) {
      const filterPhone = this.listExisted.find((x) => x.zaloUserId == user_id);
      return filterPhone ? filterPhone.phoneNumber : null;
    },
    async getPhoneList(courseId) {
      const response = await this.$api.get(
        `CustomerReceives/MappingZalo?courseId=${courseId}`,
      );
      this.phoneListMapping = response;
      await this.getListAccount();
    },

    async getListAccount() {
      const respone = await this.$api.get(`/Account/ZaloIdExist`);
      this.listExisted = respone
        .filter((item) => item.zaloUserId != null)
        .map((item) => {
          return {
            zaloUserId: item.zaloUserId,
            phoneNumber: item.phoneNumber,
          };
        });
    },

    splitSelectedPhone(user_id) {
      // Check if selectedPhone exists, and split it if it does
      if (this.selectedPhone) {
        this.parts = this.selectedPhone.split('|');

        // Return the second part if it exists
        if (this.parts[1]) {
          return this.parts[1];
        }
      }

      // If parts[1] is not found, try to get the phone number using the user_id
      const phoneNumber = this.getPhoneNumber(user_id);

      // Return the phone number or null if not found
      return phoneNumber || null;
    },

    async submitLink(user_id, phone) {
      this.$store.commit('context/setLoading', true);
      await this.$api.get(
        `/CustomerReceives/MappingZaloPortal?user_id=${user_id}&phone=${phone}`,
      );
      await this.getListAccount();
      this.$store.commit('context/setLoading', false);
    },

    async submitUnLink(user_id, phone) {
      this.$store.commit('context/setLoading', true);

      await this.$api.get(
        `/CustomerReceives/UnMappingZaloPortal?user_id=${user_id}&phone=${phone}`,
      );
      await this.getListAccount();
      this.$store.commit('context/setLoading', false);
    },

    async getListPhoneNotMap() {
      const coachList = await this.$api.get('/Account/ZaloIdCoachs');
      this.coachesList = coachList.data;
    },

    show() {
      this.$refs['zalo-list-modal'].show();
    },
    hide() {
      this.$refs['zalo-list-modal'].hide();
    },

    async getDetailGroupOA(group_id) {
      const body = {
        group_id: group_id,
      };

      // Make the API call with JSON body
      const response = await this.$api.post('/ZaloZnsLog/DetailGroup', body, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      this.zaloGroupDetail = response.data;
    },

    async getGroupOA() {
      this.$store.commit('context/setLoading', true);
      try {
        const response = await this.$api.get(`/ZaloZnsLog/GetGroupOA`);
        this.zaloGroup = response.data.groups;
        this.paging.total = response.data.total;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },

    handleCancelSuccess() {
      this.$refs['success-modal'].hide();
      this.resetModal();
    },
    handleCancel() {
      this.hide();
    },
    handleSuccessOk() {
      this.hide();
      this.resetModal();
    },
    async handleRefresh() {
      await this.getGroupOA();
    },
  },
};
</script>
<style scoped>
.no-footer .modal-footer {
  display: none;
}
.group-name {
  display: inline-block;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.bold-label {
  font-weight: bold;
  color: #333;
  text-align: left;
}

button.btn.btn-secondary.collapsed {
  margin: -15px;
}
button.btn.btn-secondary.not-collapsed {
  margin: -15px;
}
.grap-info {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 20px;
  align-items: center;
  width: 100%;
}

</style>
